.phone-input {
  flex-grow: 1;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  background: #f1f1f1;
  padding: 4px;
  border-radius: 8px;
  height: 56px;
  transition: 0.25s ease all;

  &.focused {
    box-shadow: 0 0 0px 2px #454544;
  }

  & * {
    font-family: "Manrope", sans-serif;
  }

  &__flag {
    width: 20px;
    height: 15px;
    margin-right: 8px;
  }

  &__flags {
    margin-right: 8px;
    height: 100%;
    &-top {
      height: 100%;
      background: #fff;
      padding: 8px 12px;
      color: #1f1e1c;
      font-size: 14px;
      font-family: Manrope;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      border-radius: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;
      // max-width: 147px;
      width: 125px;

      span {
        overflow: hidden;
        position: relative;
        display: inline-block;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      svg {
        min-width: 20px;
        max-width: 20px;
        height: 20px;
      }
    }
    &-list {
    }

    &.open {
      .phone-input__block {
        visibility: visible;
        opacity: 1;
      }
      .phone-input__flags-top svg {
        transform: rotate(-180deg);
      }
    }

    // &-top {
    //   background: #f1f1f1;
    //   border-radius: 7px;
    //   height: 56px;
    //   font-size: 14px;
    //   line-height: 150%;
    //   display: flex;
    //   align-items: center;
    //   color: #1f1e1c;
    //   padding: 8px 12px;
    //   border: 2px solid transparent;
    //   transition: 0.25s ease;
    //   outline: none;
    //   cursor: pointer;
    //   img {
    //     width: 20px;
    //     height: 14px;
    //     object-fit: cover;
    //     margin-right: 4px;
    //   }

    //   svg {
    //     transition: 0.3s ease;
    //     width: 16px;
    //     height: 16px;
    //     min-width: 16px;
    //     path {
    //       transition: 0.3s ease;
    //     }
    //   }
    //   &:hover {
    //     path {
    //       fill: #ff8324;
    //     }
    //   }
    // }

    // &-list {
    //   visibility: hidden;
    //   opacity: 0;
    //   transition: 0.3s ease;

    //   background: #f1f1f1;
    //   border-radius: 7px;
    //   font-size: 14px;
    //   line-height: 150%;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   color: #1f1e1c;
    //   padding: 8px;
    //   transition: 0.25s ease;
    //   max-height: 139px;
    //   overflow: auto;
    //   position: absolute;
    //   left: 0;
    //   top: calc(100% + 8px);
    //   width: 100%;
    //   &::-webkit-scrollbar {
    //     width: 6px;
    //   }
    //   &::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 6px #bdbdbd;
    //     border-radius: 12px;
    //   }
    //   &::-webkit-scrollbar-thumb {
    //     background: #272c24;
    //     border-radius: 12px;
    //   }
    // }
    // &-item {
    //   cursor: pointer;
    //   img {
    //     width: 20px;
    //     height: 14px;
    //     object-fit: cover;
    //   }
    //   &:not(:last-child) {
    //     margin-bottom: 8px;
    //   }
    // }
    // &.open {
    //   .phone-input__flags-list {
    //     visibility: visible;
    //     opacity: 1;
    //   }
    //   .phone-input__flags-top svg {
    //     transform: rotate(-180deg);
    //   }
    // }
  }

  &__block {
    border-radius: 24px;
    padding: 16px 16px 28px;
    background: #fff;
    box-shadow: 2px -2px 30px 0px rgba(116, 116, 116, 0.15);
    height: 386px;
    width: 100%;
    position: absolute;
    left: 0;
    top: calc(100% + 8px);
    display: flex;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease all;
    z-index: 2;
    @media (max-width: 480px) {
      height: 284px;
    }

    input {
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      color: #1f1e1c;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #d1d1d1;
      padding: 12px;
      &::placeholder {
        color: #454544;
      }
      &:focus,
      &:hover {
        border-color: #454544;
      }
    }

    &-list {
      margin-top: 16px;
      flex-grow: 1;
      overflow: auto;
      padding: 0 24px 0 8px;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #bdbdbd;
        border-radius: 12px;
      }
      &::-webkit-scrollbar-thumb {
        background: #272c24;
        border-radius: 12px;
      }
    }
    &-item {
      cursor: pointer;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      &:not(:last-child) {
        margin-bottom: 14px;
      }

      .phone-input__flag {
        margin-right: 12px;
      }
    }
  }
  &__input {
    background: transparent;
    border: none;
    outline: none;
    padding: 8px 12px;

    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    flex-grow: 1;
    height: 100%;

    &:hover,
    &:focus {
      background: transparent;
      border: none;
      outline: none;
    }
  }
}
