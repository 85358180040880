.subscribe {
  width: 100%;
  position: relative;
  z-index: 1;
  &__container {
    max-width: 1146px;
    margin: 0 auto;
    padding: 48px 57px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .button {
    white-space: nowrap;
  }

  &__subtitle {
    font-family: "Gotham Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 110%;
    /* or 13px */

    text-transform: uppercase;

    /* #1F1E1C */

    color: #1f1e1c;
    max-width: 460px;
  }
  &__label {
    flex-grow: 1;
    margin-right: 8px;

    &.error {
      position: relative;

      &::after {
        content: var(--input-error);
        position: absolute;
        top: -26px;
        height: 26px;
        left: 0;
        background-color: #ff4500;
        font-size: 14px;
        color: white;
        line-height: 27px;
        padding: 0 10px;
        border-radius: 6px;
        z-index: 1;
      }
      &:not(:first-child) {
        margin-top: 32px;
      }
      .phone-input {
        box-shadow: 0 0 0px 2px #ff4500;

        &.focused {
          box-shadow: 0 0 0px 2px #ff4500;
        }
      }
    }
  }

  .input {
    width: 100%;
  }

  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    /* or 44px */

    /* #272C24 */

    color: #272c24;
    margin: 12px 0 20px;
    max-width: 460px;
  }

  &__text {
    max-width: 460px;
    font-family: "Gotham Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */

    /* #1F1E1C */

    color: #1f1e1c;
  }

  &__row {
    margin-top: 41px;
    display: flex;
    align-items: center;
  }

  &__col {
    max-width: 588px;
    min-width: 588px;
    position: relative;
    z-index: 1;
  }

  &__img {
    width: 444px;
    max-width: 444px;
    display: flex;
    & > div {
      display: flex;
      margin-left: -42px !important;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 16px;
  }

  @media (max-width: 1024px) {
    &__container {
      flex-direction: column;
      padding: 0px 57px 48px;
    }

    &__img {
      align-self: flex-end;
      & > div {
        margin-left: 0 !important;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 140px;

    &__container {
      padding: 0px 16px 0;
    }

    &__col {
      max-width: none;
      min-width: auto;
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    margin-top: 80px;

    &__row {
      flex-direction: column;
      margin-top: 24px;
    }
    &__container {
      padding: 0;
    }

    &__img {
      width: 100%;
      margin-top: 30px;
      display: block;
    }

    &__label {
      margin-right: 0;
      width: 100%;
      &.error {
        margin-top: 16px;
      }
    }
    .input {
      width: 100%;
      margin-right: 0;
    }
    .phone-input {
      margin-bottom: 16px;
    }
    &__title {
      font-size: 24px;
      max-width: 300px;
    }
  }
}
