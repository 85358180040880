* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat Alternates", sans-serif;

  -webkit-appearance: none;
  appearance: none;
  backdrop-filter: none;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  &:hover,
  &:focus {
    -webkit-appearance: none;
    appearance: none;
    backdrop-filter: none;
    -webkit-tap-highlight-color: transparent;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@300;400;500;600;700;800;900&display=swap");
@import "./fonts/stylesheet.scss";
@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');
.input {
  background: #f1f1f1;
  border-radius: 7px;
  height: 56px;
  flex-grow: 1;
  margin-right: 8px;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #1f1e1c;
  padding: 0 19px;
  border: 2px solid transparent;
  transition: 0.25s ease;
  outline: none;

  &::placeholder {
    color: #828282;
  }
  &:hover {
    outline: none;
    border-color: #1f1e1c;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
  }
}

#root {
  overflow-x: hidden;
}

*.fadeInUp,
*.zoomIn {
  -webkit-transform-style: preserve-3d;
  will-change: transform;
}

html,
body {
  &.hidden-scroll {
    overflow: hidden;
    touch-action: none;
    pointer-events: none;
  }
}

html.contact-page {
  display: flex;
  min-height: 100vh;
  .header__container {
    margin-top: 0;
  }

  #root {
    display: flex;
    flex-direction: column;
  }
  body {
    width: 100%;
  }
  #root,
  .contact {
    height: 100%;
    width: 100%;
  }
}
