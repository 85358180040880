.teachers {
  width: 100%;
  margin-top: 80px;
  &__container {
    max-width: 1310px;
    margin: 0 auto;
    padding: 80px 0;
  }

  &__top {
    max-width: 753px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    /* or 44px */

    display: flex;
    align-items: center;
    text-align: center;

    /* #272C24 */

    color: #272c24;
    margin-bottom: 40px;
  }

  &__text {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: #454544;
    max-width: 558px;

    span,
    a {
      font-family: 'Gotham Pro';
      font-weight: 700;
      text-decoration: none;
      color: #454544;
    }

    a{
      transition: 0.25s ease;

      &:hover{
        color: #ff8659;
      }
    }
  }

  &__img {
    position: absolute;
    bottom: 0;
    right: -98px;
    width: 123px;
    height: 109px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 30px;
    margin-top: 64px;
  }

  &__item {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    display: flex;

    &.white-bg {
      background: #f1f1f1;
      height: 315px;
      margin-right: 30px;
    }
    &.orange-bg {
      background: #ff8659;
      height: 315px;
    }

    &.white-bg,
    &.orange-bg {
      display: inline-block;
      margin-top: 30px;
      width: calc(50% - 15px);
    }
    &-img {
      width: 100%;
      overflow: hidden;
      border-radius: 6px;
      display: flex;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
        backface-visibility: hidden;
      }
    }
    &-info {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 32px 16px 32px 16px;
      display: flex;
      flex-direction: column;
      background: #f1f1f1;
      border-radius: 6px;
      opacity: 0;
      transition: 0.3s;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #000000;
      margin-bottom: 16px;
    }
    &-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      /* or 22px */

      color: #000000;
      flex-grow: 1;
      p {
        font-family: 'Gotham Pro';
      }

      p:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    &-socials {
      margin-top: 32px;
      display: flex;
      a {
        transition: 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background: #1f1e1c;
        width: 40px;
        height: 40px;
        min-width: 40px;
        border-radius: 50%;
        &:hover {
          background: #ff8324;
        }
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  &__bottom {
    background: #f1f1f1;
    border-radius: 8px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .teachers__text {
      text-align: left;
    }

    &-img {
      width: 368px;
      margin: 25px 96px 0 0;
    }
  }

  &__subtitle {
    font-weight: 800;
    font-size: 28px;
    line-height: 120%;
    color: #000000;
    margin-bottom: 20px;
  }

  &__col {
    padding: 64px;
    max-width: 568px;
  }

  .button {
    width: fit-content;
    border: 2px solid #000000;
    background: transparent;
    margin-top: 32px;
    transition: 0.25s;

    &:hover {
      background: #000;
    }
  }

  @media (max-width: 1330px) {
    &__container {
      padding: 80px 16px;
    }
  }

  @media (max-width: 1150px) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
    &__item.white-bg {
      display: none;
    }
    &__item.orange-bg {
      height: auto;
    }
  }

  @media (max-width: 1024px) {
    &__img {
      right: -70px;
    }

    &__title {
      max-width: 620px;
    }

    &__bottom-img {
      margin-right: 0;
      margin-top: 32px;
    }

    &__bottom {
      flex-direction: column;
      align-items: center;
    }

    &__col {
      padding: 64px 0 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-direction: column;

      .teachers__text {
        text-align: center;
      }
    }

    &__subtitle {
      text-align: center;
    }
  }

  @media (max-width: 900px) {
    &__img {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 768px) {
    &__item.white-bg,
    &__item.orange-bg {
      height: 200px;
    }
    &__list {
      column-gap: 20px;
      row-gap: 20px;
      grid-template-columns: repeat(1, 1fr);
    }
    &__item.orange-bg {
      display: none;
    }
  }

  @media (max-width: 600px) {
    &__bottom {
      padding: 0 32px;
    }
    &__col {
      padding: 64px 0 0;
      width: 100%;
    }

    &__item.orange-bg {
      height: auto;
    }
  }

  @media (max-width: 480px) {
    margin-top: 70px;
    &__list {
      column-gap: 0;
      row-gap: 30px;
      grid-template-columns: repeat(1, 1fr);
    }
    &__item.orange-bg {
      display: none;
    }

    &__title {
      font-size: 24px;
      max-width: none;
      margin-bottom: 16px;
    }

    &__list {
      margin-top: 60px;
    }

    &__subtitle {
      font-size: 18px;
      margin-bottom: 24px;
    }

    &__col {
      padding: 0;
      width: 100%;
    }

    &__bottom {
      padding: 20px 20px 0;
      &-img {
        width: 100%;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    &__col {
      align-items: flex-start;

      .teachers__text {
        text-align: left;
      }
    }
    .button {
      width: 100%;
    }

    &__subtitle {
      text-align: left;
    }

    &__item {
      &-title {
        font-size: 18px;
        line-height: 130%;
        color: #000000;
        margin-bottom: 12px;
      }
      &-socials {
        margin-top: 24px;

        a:not(:last-child) {
          margin-right: 16px;
        }
      }
      &-info {
        padding: 16px 14px 16px 16px;
      }
      &-text {
        p:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  }

  @media (max-width: 360px) {
    &__item {
      &-title {
        font-size: 16px;
        line-height: 130%;
        color: #000000;
        margin-bottom: 12px;
      }
      &-text {
        font-size: 12px;
      }
      &-socials {
        margin-top: 20px;
      }
      &-info {
        padding: 16px 14px 16px 16px;
      }
    }
  }
}
