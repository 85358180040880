.main-form {
  margin-top: 80px;
  position: relative;
  z-index: 1;
  &__container {
    width: 100%;
    max-width: 1310px;
    margin: 0 auto;
    background: #f1f1f1;
    border-radius: 12px;
    padding: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__col {
    max-width: 524px;
    margin-right: 68px;
    flex-grow: 1;
  }

  &__subtitle {
    font-weight: 500;
    font-family: "Gotham Pro";

    font-size: 12px;
    line-height: 110%;
    /* or 13px */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    /* #1F1E1C */

    color: #1f1e1c;
  }

  .input {
    background: #ffffff;
    margin-right: 0;
    width: 100%;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
  }
  .phone-input {
    position: relative;
    z-index: 1;
    background: #fff;

    &__flags-top {
      background: #f1f1f1;
    }

    // &__flags {
    //   &-top,
    //   &-list {
    //     background: #ffffff;
    //   }
    // }
  }

  &__label {
    --input-error: "LOo";
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }

    &::after {
      content: var(--input-error);
      position: absolute;
      top: -26px;
      height: 26px;
      left: 0;
      background-color: #ff4500;
      font-size: 14px;
      color: white;
      line-height: 27px;
      padding: 0 10px;
      border-radius: 6px;
      z-index: 0;
      opacity: 0;
      transition: 0.25s ease;
    }
    &.error {
      position: relative;

      &::after {
        opacity: 1;
      }
      &:not(:first-child) {
        margin-top: 32px;
      }
      .input {
        border-color: #ff4500;
      }
      .phone-input {
        box-shadow: 0 0 0px 2px #ff4500;

        &.focused {
          box-shadow: 0 0 0px 2px #ff4500;
        }
      }
    }
  }
  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    color: #1f1e1c;
    margin: 12px 0 24px;
  }

  &__text {
    font-family: "Gotham Pro";

    font-size: 16px;
    line-height: 154.05%;
    color: #1f1e1c;
    margin-bottom: 40px;
  }

  &__img {
    max-width: 467px;
    width: 467px;
    display: flex;
    img {
      width: 100%;
    }
  }

  .button {
    width: 100%;
    margin-top: 24px;
  }

  @media (max-width: 1330px) {
    padding: 80px 16px;
  }

  @media (max-width: 1120px) {
    &__img {
      display: none;
    }

    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__col {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 0;
    }
    &__title,
    &__subtitle,
    &__text {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    padding: 64px 16px;
    &__container {
      padding: 64px 32px;
    }
  }

  @media (max-width: 480px) {
    padding: 0 0 64px;
    margin-top: 0;

    &__container {
      padding: 50px 16px 40px 16px;
    }
    &__col {
      align-items: flex-start;
    }
    &__subtitle {
      font-size: 10px;
    }
    &__title {
      font-size: 24px;
      max-width: 300px;
      color: #1f1e1c;
      margin: 8px 0 16px;
    }

    &__text {
      margin-bottom: 32px;
      font-size: 14px;
    }

    &__subtitle,
    &__title,
    &__text {
      text-align: left;
    }
  }
}
