.contacts {
  max-width: 1151px;
  min-width: 1151px;
  margin: 0 auto;
  margin-top: 32px;
  height: 100%;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  @media (max-width: 1200px) {
    min-width: auto;
  }
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 56px 64px;
    background: #f1f1f1;
    border-radius: 12px;
    position: relative;
  }

  &__title {
    font-weight: 800;
    font-size: 28px;
    line-height: 120%;

    color: #1f1e1c;
    margin-bottom: 24px;
  }

  &__text {
    font-family: "Gotham Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #454544;
  }

  &__col {
    max-width: 412px;
  }

  &__form {
    --input-error: "LOo";
    z-index: 1;
    &::after {
      content: var(--input-error);
      position: absolute;
      top: -26px;
      height: 26px;
      left: 0;
      background-color: #ff4500;
      font-size: 14px;
      color: white;
      line-height: 27px;
      padding: 0 10px;
      border-radius: 6px;
      z-index: 1;
      opacity: 0;
      transition: 0.25s ease;
    }
    &.error {
      position: relative;

      &::after {
        opacity: 1;
      }
      &:not(:first-child) {
        margin-top: 32px;
      }
      .input {
        border-color: #ff4500;
      }

      .phone-input {
        box-shadow: 0 0 0px 2px #ff4500;

        &.focused {
          box-shadow: 0 0 0px 2px #ff4500;
        }
      }
    }
  }
  .phone-input {
    position: relative;
    z-index: 1;
    background: #fff;
    margin: 16px 0;
    &__flags-top {
      background: #f1f1f1;
    }

    // &__flags {
    //   &-top,
    //   &-list {
    //     background: #ffffff;
    //   }
    // }
  }

  .input {
    width: 100%;
    margin: 16px 0;
    background: #ffffff;
  }

  &__imgs {
    margin-top: 47px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cost__item {
    max-width: 434px;
    margin-bottom: -180px;
    background: #ffffff;
    box-shadow: 0px 8px 32px 4px rgba(18, 20, 23, 0.08);
    border-radius: 12px;
    @media (min-width: 1700px) {
      margin-bottom: -576px;
    }

    @media (max-width: 1600px) {
      margin-bottom: -399px;
    }
    @media (max-width: 1536px) {
      margin-bottom: -399px;
    }
    @media (max-width: 1280px) {
      margin-bottom: -558px;
    }

    @media (max-width: 990px) {
      padding: 16px;
    }
    &-top {
      background: #ffffff;
      position: relative;
      flex-grow: 1;
      margin: 0;
      padding: 0;
      padding-bottom: 40px;
    }
    &-param {
      padding-right: 32px;
    }
    &-subtitle,
    .button {
      display: none;
    }
    &-scroll {
      padding: 16px 0;

      @media (max-width: 1280px) {
        margin-bottom: 16px;
      }
    }
    &-list {
      max-height: 295px;
      overflow: auto;
      margin-bottom: -32px;
      padding-bottom: 16px;
      margin-top: 0;
      padding-top: 16px;

      @media (min-width: 1700px) {
        max-height: none;
      }

      @media (max-width: 1600px) {
        max-height: 448px;
      }

      @media (max-width: 1536px) {
        max-height: 417px;
      }

      @media (max-width: 1440px) and (min-height: 800px) {
        max-height: 450px;
        padding-top: 16px;
      }
      @media (max-width: 1440px) and (max-height: 700px) {
        max-height: 235px;
        padding-top: 16px;
      }

      @media (max-width: 1366px) {
        max-height: 320px;
        padding-top: 16px;
      }

      @media (max-width: 1280px) and (min-height: 800px) {
        max-height: 375px;
        padding-top: 16px;
      }

      @media (max-width: 1280px) and (min-height: 700px) and (max-height: 760px) {
        max-height: 293px;
      }
      @media (max-width: 1280px) and (min-height: 1020px) {
        max-height: none;
        padding-top: 16px;
      }

      @media (max-width: 1024px) and (max-height: 768px) {
        max-height: 360px;
      }
      &::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #bdbdbd;
        border-radius: 12px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #272c24;
        border-radius: 12px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #000;
      }
    }
  }

  @media (max-width: 1330px) {
    .cost__item-list {
      margin-top: 0;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 16px 40px;
    .cost__item {
      &-list {
        margin-bottom: -16px;
        padding-bottom: 16px;
      }
    }
    &__container {
      padding: 32px 48px;
    }
    &__col {
      margin-right: 48px;
    }
    .cost__item {
      max-width: 373px;
    }
  }

  @media (max-width: 1024px) {
    .thanks__bottom {
      justify-content: flex-start;
    }

    @media (min-height: 1024px) {
      justify-content: flex-start;
    }
  }

  @media (max-width: 990px) {
    &__container {
      padding: 24px 32px;
    }
    .cost__item {
      min-width: 330px;
      max-width: 373px;
      margin-bottom: 0;

      &-top {
        // position: sticky;
        // padding: 16px;
        // margin: -16px;
      }
    }

    .thanks__bottom {
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    &__container {
      padding: 24px 24px;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }
    &__col {
      margin-right: 0;
    }
    .cost__item {
      display: flex;
      max-width: 412px;
      margin-top: 32px;
      min-width: auto;
      width: 100%;
      &-top {
        z-index: 1;
        position: sticky;
        margin: 0 -16px;
        padding: 0 16px 40px;
      }
      &-list {
        max-height: none;
        padding-top: 0;
        margin-top: 0;
      }
      &-scroll {
        padding: 0;
        margin: 0;
      }
    }
  }

  @media (max-width: 700px) {
    .cost__item {
      &-new {
        flex-wrap: wrap;
      }
    }
  }

  @media (max-width: 480px) {
    margin: 0;
    padding: 0;
    padding-bottom: 32px;
    &__container {
      padding: 24px 16px;
    }
    &__title {
      font-size: 24px;
      margin-bottom: 16px;
    }
    .cost__item {
      &-title {
        font-size: 20px;
      }
      &-list {
        margin-bottom: -16px;
        padding-bottom: 16px;
        width: 100%;
        max-width: none;
        padding-top: 34px;
      }
      &-new {
        span {
          font-size: 24px;
        }
      }
    }
    &__imgs {
      margin-top: 32px;
    }

    &__img {
      max-width: 70px;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .cost__item {
      width: 100%;
      min-width: auto;
      flex-direction: column;
      &-top {
        &::before {
          display: block;
        }
      }
      &-list {
      }
    }
  }
}
